<template>
    <div id="team">
        <PageTitle :title="title" />
        <!-- <div class="big-photo">
            <img src="/images/team-photo.jpg" alt="Team">
        </div> -->
        <div class="team">
            <div v-for="(member, i) in team" class="team-member" :key="i" v-bind:class="{ 'reverse' : i % 2 === 1 }">
                <div :data-aos="$store.getters.isMobile ? 'fade-up' : i % 2 === 1 ? `fade-left` : `fade-left`" class="photo">
                    <img :src="member.image" :alt="member.name">
                </div>
                <div class="description">
                    <h3 data-aos="fade-up">
                        {{member.name}}
                    </h3>
                    <h4 data-aos="fade-up">
                        {{member.title}}
                    </h4>
                     <h4 data-aos="fade-up">
                        {{member.phone}}
                    </h4>
                     <h4 data-aos="fade-up">
                        {{member.email}}
                    </h4>
                     <h4 data-aos="fade-up">
                        {{member.license}}
                    </h4>
                    <h4 data-aos="fade-up">
                        <a :href="`https://www.instagram.com/${member.ig}/`" target="_blank" rel="noopener noreferrer">@{{member.ig}}</a>
                    </h4>
                    <p v-html="member.description" data-aos="fade-up">
                    
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PageTitle from '../components/PageTitle'

export default {
    name: "Team",
    components: {
      PageTitle
    },
    data(){
      return{
        title: "Our Team",
        team: [
            {
                image: '/images/shauna-walters.jpg',
                name: 'SHAUNA WALTERS',
                title: 'Estates Director & Co-Founder, Walters | Plaxen Estates',
                phone: '310.775.1106',
                email: 'Shauna@waltersplaxen.com',
                license: 'DRE# 01728600',
                ig: 'shaunasellshomes',
                description: `Shauna Walters began representing clients in her hometown of Calabasas at age 21, quickly becoming the agent of choice due to her exceptional networking skills, magnetic personality, and an unusually mature grasp of the marketplace.<br><br>
                Shauna's nearly 20 years of experience extends across the city and beyond, catering to clients seeking high-end properties in Southern California. During her 13-year tenure as a Luxury Sales Agent with the widely acclaimed Sally Forster Jones Group, she has honed her skills and built a reputation for persistent excellence. Notably, in 2021 she achieved a record-breaking $44 million sale of the Brentwood Oasis, turning the heads of many and solidifying her position as a leading force in the industry.<br><br>
                Shauna's passions include her young son, Ryder, who is the light of her life. As the daughter of a professional athlete and a star softball player herself, she's committed to staying in shape and participating in activities like SoulCycle, boxing, and hot yoga. Additionally, Shauna is deeply involved in numerous dog charities, channeling her love for animals into making a positive impact both professionally and personally. With two rescue dogs, Rosie and River, and a vegan lifestyle, Shauna continues to be a dedicated advocate for the welfare of animals. Also licensed in Florida with a focus in Miami, Shauna sells properties on both West and East Coasts, bringing her expertise and love of luxury real estate to a diverse range of clients. She currently resides in the charming community of Toluca Lake and has lived all throughout Los Angeles, including Downtown, Malibu, and West Hollywood.`
            },
            {
                image: '/images/about/team-nicole.jpg',
                name: 'NICOLE PLAXEN',
                title: 'Estates Director & Co-Founder, Walters | Plaxen Estates',
                phone: '310.710.7167',
                email: 'Nicole@waltersplaxen.com',
                license: 'DRE# 01772151',
                ig: 'nicole.deleo.plaxen',
                description: `Nicole Plaxen has enjoyed a stellar career. She honed her real estate expertise under the mentorship of one of the world's most renowned figures in luxury real estate, Sally Forster Jones. During her tenure as the team’s Executive Vice President of Sales from 2007 to 2023, Nicole played a pivotal role in driving the group’s sales to an impressive $10+ billion. She oversaw a team of 35 members and personally participated in over 4,000 transactions, including landmark sales such as the Spelling Manor for $85 million and Markus Persson's Trousdale estate for $70 million. Her client roster boasts professional athletes and high-net-worth individuals from around the globe.<br><br>
                Nicole's deep affinity for real estate traces its roots back to her childhood, where she watched her mother skillfully navigate the world of buying and flipping homes throughout Los Angeles. This early exposure ignited a lifelong passion that would shape her remarkable journey.<br><br>
                As a longstanding resident of Beverly Hills, Nicole's philanthropic commitments extend to various local and international causes. She embraces the opportunity to contribute positively to communities and causes close to her heart. In her personal life, Nicole finds fulfillment as a devoted mother to her two daughters, imparting to them the joys of life and the significance of giving back. She also harbors a deep love for dance, a passion for art, and a genuine enthusiasm for travel, fostering a rich appreciation for diverse cultures and experiences that enrich her perspective.`
            },
            {
                image: '/images/about/team-jana.jpg',
                name: 'Jana Townsley',
                title: 'Executive Assistant',
                phone: '323.577.2738',
                email: 'jana@waltersplaxen.com',
                license: 'DRE# 01994583',
                ig: 'jana.town',
                description: `Jana Townsley is a dedicated real estate professional based in Los Angeles, where she has lived for half of her life. Originally hailing from Central Europe, Slovakia, her strong work ethic and determination are evident in her tireless efforts to fulfill her supporting role to teams and individuals.<br><br>
                Since getting her license in 2016, Jana has been a valuable asset to numerous agents in prestigious brokerages like Keller Williams, Coldwell Banker and Compass. Currently, she is a vital member of the Walters|Plaxen Estates at The Beverly Hills Estates brokerage. Jana's ability to resolve issues and maintain a calm presence is particularly helpful in navigating even the most challenging real estate transactions.<br><br>
                What sets Jana apart is not only her market knowledge but also her sincere interest in people. Her exceptional verbal skills, professionalism, and a touch of witty humor make her a valuable part of the team. She excels at providing comprehensive guidance, helping individuals and families achieve their real estate dreams successfully and with minimal stress.<br><br>
                In addition to her professional life, Jana is a devoted single mother of three, demonstrating her commitment to her family and her clients alike.<br><br>
                In her free time, Jana enjoys spending quality moments with her kids. Outside of work and family, Jana finds solace at the beach, in the practice of yoga or pilates, in globe-trotting adventures, discovering new culinary experiences, and delving into the intriguing world of the quantum field.<br><br>
                Furthermore, Jana's multilingual abilities in English, Slovak, and Czech broaden her capacity to serve a diverse clientele, making her a versatile and well-rounded real estate professional.`
            },
            {
                image: '/images/about/team-laugharn.jpg',
                name: 'LAUGHARN PIEROSE',
                phone: '310.429.6653',
                email: 'laugharn@waltersplaxen.com',
                license: 'DRE# 02104106',
                title: 'Agent',
                ig: 'laugharnpierose',
                description: `A fourth-generation Los Angeles native, Laugharn Pierose's real estate career is guided by a deep love for the greater LA area, a penchant for design, and a heart for helping her clients.<br><br>
                Born and raised in Malibu, Laugharn grew up taking advantage of the active outdoor lifestyle California has to offer, spending her weekend's surfing and riding horses. After graduating from the University of Southern California, Laugharn moved to New York City to pursue a career in fashion. In her time across the country, her love for California grew even greater, and after three years she found herself back on the west coast working in media sales and advertising for Viacom and The Honest Company.<br><br>
                Laugharn was drawn to real estate throughout her life and her knowledge of Los Angeles and extended network made it natural for her to take the leap and pursue her passion. She truly believes Los Angeles has a place for everyone, whether you are looking in Hollywood, the Pacific Palisades, Mar Vista, or Silverlake. Her heart's desire is to help her clients find their perfect home, while also negotiating the best deal for their investment. Her sales background and marketing experience give her an extensive understanding of the housing market and the importance of a sound investment.<br><br>
                In her personal time, you can find Laugharn at the beach with family and friends, exploring new restaurants, or planning her next travel adventure.`
            },
            {
                image: '/images/about/ashley-kehoe.jpg',
                name: 'ASH MARTIN',
                phone: '310.957.1056',
                email: 'ash@waltersplaxen.com',
                license: 'DRE# 01960877',
                title: 'Agent',
                ig: 'iamashmartin',
                description: `Born and raised along L.A.'s sunny west side, real estate agent Ashley Martin approaches every client and job with the same goal in mind — to hand deliver the Southern California lifestyle, one home at a time.<br><br>
                She's been buying, selling, flipping, and facelifting homes for the past decade. No stranger to a hammer, Ashley blossomed what had been a hobby of demo + design into a career as a full-service agent who also knows the ins and outs of construction, development, and bespoke renovation. To date, she's represented clients from 100+ properties, combining real estate instinct with a unique eye for design.<br><br>
                With a background in software sales and start-up life, Ashley possesses the versatility needed to handle the ever changing real estate market in Los Angeles. With 90% of her business coming from referrals, Ashley is focused on the client experience. From first time home buyers to seasoned investors, she delivers an educated and supported journey for all clients.<br><br>
                Consider Ashley your emotional-support human, here to demystify the buy/sell process while anticipating all the things you never knew you needed to know. She's energized by creating a special, tailored experience for every client through all aspects of purchase, selling, construction, and design. Her toolkit includes a time-tested approach to market data, no-nonsense negotiation tactics, and a wide network of specialists from inspectors to licensed contractors.<br><br>
                Technical skills aside, it's Ashley's innate ability to empathize and connect with clients, agents, and contractors alike that makes her someone people trust and want to work with time after time.<br><br>
                Whether you're looking to sell or buy in Los Angeles, Ashley looks forward to making the process as seamless as possible!`
            },
            {
                image: '/images/about/amanda-miller.jpg',
                name: 'AMANDA MILLER',
                phone: '310.819.6414',
                email: 'amanda@waltersplaxen.com',
                license: 'DRE# 02074029',
                title: 'Agent',
                ig: 'realestatebeverlyhills',
                description: `Amanda Miller was born in New York, raised in Arizona, and now calls Beverly Hills her home sweet home. She is a seasoned realtor specializing in the luxury market with experience in residential sales, leases, and land deals.<br><br>
                She graduated from Phoenix College with a degree in arts & business communications where she also competed on the women's golf team and played in the Daytona Beach Nationals Competition. Amanda brings this competitive spirit and business acumen to every deal to help her clients navigate through the home buying and selling process.<br><br>
                Amanda truly enjoys learning about her clients' real estate goals & desires so she can create a plan for a successful outcome. Her values go far beyond transactions, she is a very actively involved with her Beverly Hills community. She loves to support local events, charity golf tournaments, women's & children's foundations, awareness walks and is always seeking additional ways to give back to people.<br><br>
                Along with Beverly Hills, Amanda is very knowledgeable on the many neighborhoods of LA including West Hollywood, Toluca Lake, Hollywood Hills, Santa Monica, Malibu, and Topanga. Her experience and relationships with local lenders, escrow and title companies, bankers & lawyers, ensure a seamless transaction process everyone. Amanda is joined by a successful & supportive team, WALTERS | PLAXEN, within The Beverly Hills Estates.`
            },
            {
                image: '/images/about/team-francesca.jpg',
                name: 'FRANCESCA ALLEGRO',
                phone: '818.913.8535',
                email: 'Francesca@waltersplaxen.com',
                license: 'DRE# 02192834',
                title: 'Agent',
                ig: 'francesca.estates',
                description: `Born and raised amidst the elegance of Milan, Italy, Francesca brings a multicultural flair to the world of high-end design and real estate. Her international upbringing has cultivated a unique perspective, seamlessly blending European sophisticated style with the vibrant energy of Los Angeles.<br><br>
                Fluent in two languages, she serves as a cultural bridge for clients seeking a truly global real estate experience. With an education in interior design, her aesthetic insight adds an extra layer of finesse to every property transaction.<br><br>
                With seven years of sales experience and a background in hospitality, Francesca has instilled a diverse network, including connections within the entertainment industry and among professional athletes. Francesca's journey and passion for architecture and design has expanded under the mentorship of the visionary Designer/Developer partnership of Jae Omar Design and JVE Development, specializing in the artistry of luxury development in LA. Her understanding of the construction and building process positions her as a trusted guide in navigating the intricate world of real estate.<br><br>
                Furthermore, Francesca is thrilled to be part of the accomplished team at Walters | Plaxen, industry leaders with almost 40 years of combined experience, representing some of the finest properties in Los Angeles.<br><br>
                Whether you're listing a new construction, aspiring to own a luxurious estate or making a property investment, Francesca is excited to turn your real estate dreams into reality with the expertise and dedication of her empowering and uplifting women led team.`
            },
            {
                image: '/images/about/team-alexandra.jpg',
                name: 'Alexandra Miklosova',
                phone: '310.405.3375',
                email: 'alexandra@waltersplaxen.com',
                license: 'DRE# 01770415',
                title: 'Agent',
                ig: 'the.listingagent',
                description: `In the realm of real estate excellence, Alexandra Miklosova shines as a beacon of sophistication and wealth creation. Renowned as a seasoned real estate advisor, global marketing strategist, and investor, she specializes in crafting wealth through strategic real estate endeavors. Currently residing in Bel Air, Alexandra eagerly extends her services to Los Angeles, aligning herself with the esteemed real estate emporium, The Beverly Hills Estates.<br><br>
                In a remarkable synergy of values and vision, Alexandra proudly integrates into Walters Plaxen Estates, marking a significant chapter in her journey. This powerhouse of female agents represents everything dear to her—empowerment, experience, elegance, and an unwavering commitment to excellence.<br><br>
                Walters Plaxen Estates stands out not only for its impressive portfolio in opulent Bel Air but also for the collaborative synergy of empowerment and experience. This integration transcends a mere career move; it's a strategic alignment resonating with Alexandra's core beliefs, amplifying her influence in female empowerment and business leadership.<br><br>
                Alexandra extends her influence as a key influencer on the board of the Women Business Network and holds a strategic partnership on the board of the Women's Council of Realtor.<br><br>
                Unstoppable in leaving indelible marks, Alexandra expands her expertise from Palm Springs to Los Angeles. Renowned as The Listing Agent, she excels in representing listings and expanding real estate portfolios. Alexandra's avant-garde marketing and cutting-edge social media strategies ensure top dollar for homes through unique selling approaches and exceptional negotiation skills.<br><br>
                A notable achievement includes a Palm Springs home under her guidance selling for $200,000 over asking, a result of a strategic partnership with a local art gallery. Alexandra seamlessly integrates her efforts, Fine Art and Fine Estates, staging open houses with first-class art, and creating a unique ambiance for each listing.<br><br>
                The Listing Agent, Alexandra is your dedicated strategic partner, shaping bespoke real estate strategies aligned with your goals. Whether selling, guiding purchases, exploring investments, or building portfolios, she catalyzes success.<br><br>
                Eager to earn your trust and business, Alexandra invites you to book a client consultation. Let her "Move You" to Exceptional Real Estate Heights!`
            },
            {
                image: '/images/about/team-leah.jpg',
                name: 'Leah Emrani',
                phone: '818.606.1646',
                email: 'Leah@waltersplaxen.com',
                license: 'DRE# 02223317',
                title: 'Agent',
                ig: 'leah.emrani',
                description: `Born and raised in the vibrant city of Los Angeles, Leah's venture into real estate commenced in her formative years, driven by an innate appreciation for exquisite homes and a profound interest in development. Surpassing conventional timelines, she graduated high school at the age of 16 and swiftly secured four Associate degrees from Los Angeles Pierce College. Currently dedicated to obtaining a Bachelor's of Science Degree in Real Estate Development at the prestigious University of Southern California, Leah coherently blends academic acumen with practical experience.<br><br>
                Leah's professional narrative is enriched by impactful internships at Lucent Capital and NAV Capital Consulting. Notably, her journey includes over a year of commitment to Shauna Walter's residential real estate team, now flourishing as a vital member of The Walters Plaxen Estates. Having acquired her real estate license at 18, Leah's mission transcends transactions; she aspires to curate a lifestyle for her clients, guaranteeing not just a property but a home that radiates lasting joy. With unwavering determination and genuine passion, Leah pledges to secure her clients the finest deals, ensuring their real estate journey is both seamless and profoundly fulfilling.<br><br>
                In her free time, Leah finds joy in the company of her loved ones, relishing moments by the beach or embarking on invigorating hikes together. Her passion for exploration extends to traveling, where she delights in forging connections with new faces and experiencing the richness of diverse cultures.`
            },
            {
                image: '/images/about/team-brooke.jpg',
                name: 'Brooke Garelick',
                phone: '310.741.7377',
                email: 'brooke@waltersplaxen.com',
                license: 'DRE# 02159940',
                title: 'Agent',
                ig: 'brookkeee',
                description: `Meet Brooke, an emerging force in the Real Estate industry, seamlessly blending a keen eye for design with a business savvy execution. With a track record of overseeing over $1 Billion in real estate transactions as a core team member on a top-producing team, Brooke sets the bar for reliability and excellence.<br><br>
                Transitioning from a background in Entertainment Business Management, Brooke has cultivated a passion-driven approach and meticulous attention to detail as a real estate agent.<br><br>
                A Los Angeles native, Brooke earned her Bachelor of Arts degree in Media Studies from the esteemed University of California, Berkeley, all while excelling as a student-athlete. Returning to her roots, she embodies the fast-paced, forward-thinking spirit of Los Angeles, exemplifying an unparalleled hustle that delivers results.<br><br>
                In Brooke, clients find a dedicated Real Estate professional whose passion, integrity, and commitment to excellence redefine the client experience. Whether you're pursuing your dream home or selling your property, <br><br>
                Brooke is your trusted partner every step of the way.`
            },
            {
                image: '/images/about/team-lindsay-segal.webp',
                name: 'Lindsay Segal',
                phone: '310.721.1383',
                email: 'lindsay@waltersplaxen.com',
                license: 'DRE# 02062329',
                title: 'Agent',
                ig: 'lindsay_segal',
                description: `Lindsay brings a meticulous attention to detail to every phase of a real estate transaction, ensuring that nothing is overlooked. Her exceptional work ethic is a testament to integrity, energy, and a commitment to providing creative and attentive service. Above all else, Lindsay prioritizes her clients' satisfaction, striving to exceed their expectations at every turn. By attentively listening to their desires and requirements, she consistently achieves optimal outcomes in each transaction.<br><br>
                With a background in the fashion industry, Lindsay brings a unique perspective to real estate, blending her innate sense of style and design expertise into her practice. She offers invaluable guidance on marketplace trends, neighborhood dynamics, and the nuanced meanings within contract provisions, ensuring her clients are well-informed throughout the process. Lindsay's success is further underscored by her adept communication skills and strong negotiation abilities, which enable her to advocate effectively on behalf of her clients.<br><br>
                Throughout every transaction, Lindsay takes pride in delivering a seamless experience from beginning to end. Her dedication to excellence and her unwavering commitment to client satisfaction distinguish her as a trusted advisor and partner in the real estate journey. Lindsay is not only a skilled professional but also a passionate advocate for her clients' best interests, ensuring that their real estate goals are achieved with confidence and peace of mind.`
            },
            {
                image: '/images/about/team-hilbert.webp',
                name: 'Necole Hilbert',
                phone: '310.871.2949',
                email: 'Necole@waltersplaxen.com',
                license: 'DRE# 02040331',
                title: 'Agent',
                ig: 'necolehilbert',
                description: `Necole Hilbert is a dynamic real estate professional bringing with her a wealth of experience and expertise in the luxury real estate market. Born and raised amidst the serene beauty of Palm Springs, Necole developed a deep appreciation for elegant living and exquisite architecture from an early age.<br><br>
                After completing her education, Necole made the bold decision to move to Los Angeles to pursue her passion for real estate. Over the past decade, she has immersed herself in the vibrant Los Angeles market, quickly establishing herself as a trusted advisor to clients seeking the epitome of luxury living.<br><br>
                Throughout the early years of her career, Necole had the privilege of working alongside and learning from some of the top-producing agents in Los Angeles. Immersed in the world of high-end luxury homes, she honed her skills in navigating the intricate nuances of the market, consistently delivering exceptional results for her clients.<br><br>
                Beyond her professional pursuits, Necole finds solace and rejuvenation in activities such as hot yoga and pilates. These practices not only keep her physically fit but also instill a sense of balance and tranquility.<br><br>
                Reach out to Necole and experience firsthand the dedication and excellence that make her a beacon in the Los Angeles's luxury real estate market. Driven by a passion for excellence and a relentless pursuit of success, Necole Hilbert is poised to make an indelible mark. Whether you're buying or selling, Necole Hilbert is your partner in navigating the world of high-end real estate with expertise, care, and a personal touch that makes all the difference.`
            },
            {
                image: '/images/about/team-yona.webp',
                name: 'Yona Lador',
                phone: '310.923.2387',
                email: 'yona@waltersplaxen.com',
                license: 'DRE# 02111718',
                title: 'Agent',
                ig: 'yonalador',
                description: `Yona embarked on his real estate journey in 2020 and has since rapidly ascended to prominence within the industry, earning a distinguished spot in the top 6% of the Berkshire Hathaway global network by 2022. His success is driven not only by his keen acumen for the market but also by his deep-seated passion for building authentic relationships with clients. Yona finds immense fulfillment in guiding individuals and families through one of life’s most monumental decisions, and he is unwavering in his commitment to ensuring they achieve the best possible results.<br><br>
                In 2024, Yona made the strategic decision to elevate his career by joining Walters|Plaxen Estates. This move allows him to leverage their extensive expertise and resources, providing his clients with unparalleled service and support in the competitive real estate landscape.<br><br>
                Raised across a tapestry of cultures—from the bustling streets of Hong Kong to the vibrant neighborhoods of Philadelphia, the sunny shores of California and Hawaii, and the rich landscapes of Israel—Yona's diverse upbringing has shaped his worldview and enhanced his ability to connect with people from all walks of life. This global perspective not only enriches his understanding of client needs but also empowers him to navigate the intricacies of various markets with finesse.<br><br>
                In addition to his real estate prowess, Yona possesses valuable expertise in construction and security assessments, equipping his clients with the knowledge and confidence necessary to make well-informed decisions in an ever-evolving landscape.<br><br>
                When he’s not busy closing deals and exceeding client expectations, Yona is an avid sports enthusiast who finds joy in surfing the waves and exploring the great outdoors. Above all, he treasures quality time with his family, cherishing the moments that inspire him to continue striving for excellence both personally and professionally.`
            },
        ],
      }
    },
    metaInfo: {
        title: 'Team',
        titleTemplate: '%s | WALTERS | PLAXEN',
        meta: [{
            name: 'description',
            content: "WALTERS | PLAXEN"
        }]
    },
}
</script>

<style lang="scss" scoped>
.big-photo{
    margin: 100px;
    overflow: hidden;
    img{
        width: 100%;
    }
}
.team{
    margin: 150px;
}

.team-member{
    display: flex;
    gap: 50px;
    margin-top: 50px;
    max-width: 900px;
    margin: 100px auto 0;
    // &.reverse{
    //     flex-direction: row-reverse;
    //     text-align: right;
    // }
    .photo{
        width: 40%;
        img{
            width: 100%;
        }
    }
    .description{
        width: 60%;
        text-align: left;
        h3{
            font-family: poppins, sans-serif;
            font-weight: 275;
            font-size: 35px;
            line-height: 26px;
            margin:40px 0 10px;
        }
        h4{
            font-family: poppins, sans-serif;
            font-weight: 275;
            font-size: 18px;
            line-height: 26px;
            margin-top: 0;
            margin-bottom: 5px;
        }
        a{
            text-decoration: none;
            color: #000;
        }
    }
}

@media (max-width: $tablet) {
    .big-photo{
        margin: 40px 0;
        img{
            width: 100%;
        }
    }
    .team{
        margin: 40px;
    }
    .team-member{
        flex-direction: column;
        gap: 40px;
        margin-top: 50px;
        max-width: 900px;
        margin: 50px auto 0;
        // &.reverse{
        //     flex-direction: column;
        //     text-align: left;
        // }
        .photo{
            width: 100%;
            img{
                width: 100%;
            }
        }
        .description{
            width: 100%;
        }
    }
}
</style>